<template>
  <s-container class="no-bg">
    <div class="s-qulicksearch">
      <div class="s-qulicksearch-logo text-center">
        <img src="@/assets/img/modules/search/logo.png"/>
        <h2>国家电网有限公司专利检索</h2>
      </div>
      <div class="s-qulicksearch-search">
        <div class="s-qulicksearch-inputbox">
          <input type="text" placeholder="请输入要检索的专利信息"/>
          <a-tooltip>
              <template slot="title">
                智能关键词
              </template>
              <span class="s-smart-keywords" title="" @click="showModal">词</span>
            </a-tooltip>
        </div>
        <button type="button">检索</button>
      </div>
    </div>
    <!--智能关键词弹窗-->
    <a-modal v-model="visible" ok-text="确认" cancel-text="取消" title="智能关键词" @ok="handleOk">
        <div>
        <a-input-group>
          <a-row :gutter="8">
            <a-col :span="14">
              <a-input placeholder="请输入关键词" />
            </a-col>
            <a-col :span="5">
               <a-button type="primary">
                查询
              </a-button>
            </a-col>
          </a-row>
       </a-input-group>
        <a-checkbox class="pt10">
            全选/取消
        </a-checkbox>
        <div class="s-qulicksearch-keywordsresult"></div>
        <p class="pt5">1、支持中文、英文两种语言</p>
        <p>2、最多支持三个单词联合查询，例如:路况 监控 系统。</p>
        </div>
      <div>
      </div>
    </a-modal>
  </s-container>  
</template>
<script>

export default {
    name:'QuickSearch',
    data() {
      return {
        visible:false
      }
    },
    methods: {
      showModal() {
        this.visible = true;
      },
      handleOk(e) {
        console.log(e);
        this.visible = false;
      }
    }
  
}
</script>

<style scoped>
</style>>
